import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    actions,
    LoadingAction,
    SuccessMessageAction
} from "mindsets-js-sdk";
import Modal from 'react-responsive-modal';

import ShareTo from "./ShareTo";
import {microsoftAuthenticate} from "utils/microsoft";

let client;

export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const [accessDenied, setAccessDenied] = useState(false);

    function initMicrosoftClient() {
        return Promise.resolve()
            .then(() => dispatch(LoadingAction(true, 'Signing to Microsoft SDS')))
            .then(() => microsoftAuthenticate())
            .then(MSClient => {
                client = MSClient;
            });
    }

    function fetchClasses() {
        return initMicrosoftClient()
            .then(() => dispatch(LoadingAction(true, 'Retrieving your Classes')))
            .then(() => new Promise((resolve, reject) => {
                client.api('/education/me/classes')
                    .get()
                    .then(response => {
                        if (response.value.length > 0) {
                            resolve(response.value.map(course => {
                                return {
                                    id: course.id,
                                    name: course.displayName
                                };
                            }));
                        } else {
                            reject('The Microsoft SDS account you have logged in with does not have any classes yet. Please create a class first.');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        if ('AccessDenied' === error.code) {
                            setAccessDenied(true);
                            reject(error);
                        } else {
                            reject('Sorry. There was a problem with your request. Please reload the page and try again or come back later.');
                        }
                    });
            }));
    }

    function fetchStudents(class_id) {
        dispatch(actions.LoadingAction(true, 'Loading Class Students'));
        return client.api(`/education/classes/${class_id}/members`)
            .get()
            .then(response => response.value.filter(student => {
                return student.id && student.userPrincipalName && student.givenName && student.surname;
            }).map(student => {
                return {
                    id: student.id,
                    first_name: student.givenName,
                    last_name: student.surname,
                    email: student.userPrincipalName
                }
            }));
    }

    function sendAssignment(selected_class) {
        dispatch(SuccessMessageAction(`Mindsets Code (${lesson.code.toUpperCase()}) has been successfully assigned to selected class ${selected_class.name}`, true));
        dispatch(LoadingAction(false));

        return Promise.resolve();
    }

    function isNotReady() {
        return false;
    }

    function renderErrorModalContent() {
        const consentUrl = encodeURI(`https://login.microsoftonline.com/organizations/v2.0/adminconsent?` +
            `client_id=${process.env.PUBLIC_OFFICE_OAUTH_ID}` +
            `&redirect_uri=${window.location.origin}` +
            '&scope=https://graph.microsoft.com/EduRoster.Read https://graph.microsoft.com/EduRoster.ReadBasic');

        return (
            <div className="assignment">
                <div className='assignment-header'>
                    <div className="assignment-icon-box">
                        <div className="assignment-icon-box-internal">
                            <svg viewBox="0 0 24 24" focusable="false" width="24" height="24" className=" NMm5M hhikbc">
                                <path d="M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7z"/>
                                <path
                                    d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 0 0-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"/>
                            </svg>
                        </div>
                    </div>
                    <div className='assignment-title'>Admin Consent Required</div>
                </div>
                <div className='assignment-body'>
                    <div className='mx-3 mb-3'>
                        <p>Admin consent is required to continue. Please share the below link to your school IT
                            administrator to grant access to our rostering features.</p>
                        <div className='center'>
                            <input type="text" className='admin-consent-link' id='admin-consent-link'
                                   defaultValue={consentUrl}/>
                            <div className='admin-consent-link-display'>{consentUrl}</div>
                            <strong>
                                <a href={consentUrl} onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();

                                    const copyText = document.getElementById("admin-consent-link");
                                    copyText.focus();
                                    copyText.select();
                                    copyText.setSelectionRange(0, 99999);
                                    document.execCommand("copy");
                                    dispatch(SuccessMessageAction('Copied'));
                                }}>COPY LINK</a>
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderErrorModal() {
        return (
            <Modal
                open={accessDenied}
                onClose={() => setAccessDenied(false)}
                showCloseIcon={true}
                classNames={{
                    modal: 'assignment-modal',
                    closeButton: 'assignment-close-button'
                }}
                center
            >
                {renderErrorModalContent()}
            </Modal>
        )
    }

    return (
        <ShareTo
            fetchClasses={fetchClasses}
            fetchStudents={fetchStudents}
            event_prefix={'microsoftTeams'}
            provider={'office'}
            isNotReady={isNotReady()}
            sendAssignment={sendAssignment}
            renderErrorModal={renderErrorModal}
        />
    );
}
