import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {actions} from "mindsets-js-sdk";

import {isValidClassCode} from 'utils';
import analytic from 'utils/Analytic';
import {LogInCodeAction} from "../../../actions/login_actions";


export default function ByCode() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState('');
    const [error, setError] = useState(null);

    function toggleOpen(e) {
        e.preventDefault();
        setOpen(!open);
    }

    function onCodeChange(e) {
        e.preventDefault();
        setCode(e.target.value.trim());
        setError(null);
    }

    function onJoin(e) {
        e.preventDefault();
        setError(null);
        dispatch(LogInCodeAction({code}))
            .then(response => window.location.href = '/hello/' + response.stream_code)
            .catch(() => {
                setError('Invalid Mindsets Code.');
                analytic.log('joinByCodeError')
            })
            .then(() => dispatch(actions.LoadingAction(false)));
    }

    function renderTitle() {
        const fa_class = open ? ' fa-chevron-up ' : ' fa-chevron-down '
        return (
            <div className="login-by-code-title flex-row flex-box-2-5 flex-vh-center">
                <div>{`or join as a`}</div>
                <a className="link-new" href="#" onClick={toggleOpen}>
                    <span>{`guest`}</span>
                    <i className={"fas " + fa_class}/>
                </a>
            </div>
        )
    }

    function renderBody() {
        if (open) {
            return (
                <div className="section">
                    <form className="flex-column flex-vh-center flex-box-5-10" onSubmit={onJoin}>
                        <div className="flex-row flex-vh-center flex-box-2-5">
                            <input
                                className="login-by-code-input input-new browser-default"
                                type="text"
                                name="code"
                                placeholder="Enter CODE"
                                maxLength="10"
                                autoComplete="off"
                                value={code}
                                onChange={onCodeChange}
                            />

                            <button
                                className="btn btn-challenge btn-large"
                                onClick={onJoin}
                                disabled={!isValidClassCode(code)}
                            >
                                {`Join`}
                            </button>
                        </div>

                        <div className="login-by-code-error">
                            {error}
                        </div>

                        <div className="center-align">
                            {`Get a Mindsets Code from your teacher.`}
                            <br/>
                            {`As a guest you will not be able to save or return to your work.`}
                            <br/>
                            {`You need to sign up or sign in to do this.`}
                        </div>
                    </form>
                </div>
            )
        }
    }

    return (
        <div className="login-by-code">
            <div className="section">
                <div className="divider"/>
            </div>

            {renderTitle()}

            {renderBody()}
        </div>
    );
}
